import styled from '@emotion/styled';
import { css } from '@emotion/react';
import theme from '../../config/theme';

const wrapper = css``;

const image = css`
  padding: 0 4px;
  object-fit: cover;
  border-radius: 10px;
  height: 140px;
  max-height: 100%;
  loading: lazy;
  margin-bottom: 10px;
  width: 100%;
  outline: none;
  cursor: pointer;
  transition: transform 0.2s;
  :hover {
    transform: scale(0.9);
  }
`;

const header = css`
  width: 100%;
  padding: 10px;
  font-family: Helvetica, Roboto, 'Segoe UI', Calibri, sans-serif !important;
  font-size: 21px;
  font-weight: 300;
  line-height: 24px;
  color: #292f33;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgba(15, 70, 100, 0.12);
`;

const ParagraphContent = css`
  p{
    font-size: ${theme.fontSize.body};
    line-height: 1.55em;
    font-weight: ${theme.fontWeight.body};
    font-family: ${theme.fontFamily.body};
    margin-bottom: 10px;
    color: ${theme.fontColor.body};
    text-align:left;
  }
  h2{
    color: ${theme.fontColor.heading};
  }
  span{
    color: black;
    font-size: ${theme.fontSize.body};
    font-weight: 600;
    line-height: 2em;
    font-family: ${theme.fontFamily.heading};
  }
  ul {
    list-style:none;
    padding:0;
    margin:0;

    @media (max-width:420px) {
      max-width:300px;
      overflow-x:auto;
    }
    li {
      font-size: ${theme.fontSize.body};
      line-height: 1.55em;
      font-weight: ${theme.fontWeight.body};
      font-family: ${theme.fontFamily.body};
      margin-bottom: 10px;
      color:#656363;
      display:block;
      svg {

        color:#498fe0 !important;
      }
    }
    p {
      display: flex;
      margin-left: 10px;
      span{
        font-size: ${theme.fontSize.body};
        line-height: 1.55em;
        font-weight: ${theme.fontWeight.body};
        font-family: ${theme.fontFamily.body};
        margin-bottom: 10px;
        color: ${theme.fontColor.body};
        text-align:left;
        margin-left: 10px;
      }
    }
  }

  button {
    transition:all 0.5s;
    color: #498fe0 ;
    margin-left: 0.2em;
    font-size: ${theme.fontSize.body};
  }

  @media (max-width: 768px) {
    margin:0px auto;
    text-align:left;
  }
  @media (max-width: 480px) {
    .MuiGrid-item {
      padding:0;
    }
    p {
      display:inline-block;

    }
    span {
      display:inline-block;
      margin-right:5px;
    }
  }
}
`;
const mobileContentFlex = css`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;
const ExtraPadding = css`
  @media (max-width: 768px) {
    padding: 0px;
  }
  @media (max-width: 480px) {
    padding: 0px;
  }
`;

const RightTopSection = styled.div`
  h4 {
    text-align: left;
  }
  p {
    display: inline-block;
    margin-left: 5px;
  }
`;

const TopSection = styled.div`
  padding: 5px;
  h4 {
    color: #3c4858;
    margin: 20px 0px;
    font-size: 1.72rem;
    line-height: 1.55rem;
    text-align: left;
    font-family: ${theme.fontFamily.heading};
    display: flex;
    align-items: center;
  }
  strong {
    color: #3c4858;
    min-height: unset;
    font-family: ${theme.fontFamily.heading};
    font-weight: ${theme.fontWeight.heading};
    display: block;
    margin-bottom: 10px;
    text-align: left;
    text-decoration: none;
    @media (max-width: 769px) {
      text-align: left;
    }
  }
  p {
    color: ${theme.fontColor.body};
    font-size: ${theme.fontSize.body};
    line-height: 1.55em;
    text-align: justify;
    font-weight: ${theme.fontWeight.body};
  }
`;

const block = css`
  display: block;
`;

const skypeLogo = css`
  color: #0078ca;
  font-size: 30px;
  margin-right: 10px;
`;

export default {
  wrapper,
  image,
  header,
  ParagraphContent,
  ExtraPadding,
  TopSection,
  RightTopSection,
  block,
  skypeLogo,
  mobileContentFlex,
};
