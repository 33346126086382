import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'layouts';
import '../styles/prism';
import GridContainer from '../components/Grid/GridContainer.jsx';
import GridItem from '../components/Grid/GridItem.jsx';
import styles from '../styles/partner.styles';
import { useTranslation } from 'react-i18next';
import getHeaderLInks from '../shared/headerLinks';
import industrialParkDetailStyles from '../styles/industrialParkDetails';
import { create } from 'apisauce';
import Constants from '../../static/Constants';
import { Container, Grid } from '@material-ui/core';
import { ThreeDots } from 'react-loader-spinner';
// import MapParallax from '../components/MapView/MapParallax.jsx';
import { Slide } from 'react-reveal';
import Twitter from '@material-ui/icons/Twitter';
import NavPills from '../components/NavPills/NavPills';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import MapWrapper from '../components/GoogleMap/MapWrapper';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import processData from '../shared/ipAttributes.js';
import ImageCarousel from '../components/ImageCarousel';
import { InfoOutlined } from '@material-ui/icons';
import TwitterIcon from '@material-ui/icons/Twitter';
import YoutubeIcon from '@material-ui/icons/YouTube';
import Instagram from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import { css } from '@emotion/react';
import { AiFillSkype } from 'react-icons/ai';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import Button from '@material-ui/core/Button';
import { isEmpty, isNumber } from 'lodash';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import LanguageIcon from '@material-ui/icons/Language';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';

const apiSauceInstance = create({ baseURL: Constants.apiUrl });
const apiSauceInstanceMapBox = create({
  baseURL: Constants.mapBoxGeoCodingUrl,
});

const baseURL = Constants.apiUrl;
const imageFolderPath = '/resources/CountryImages/';

const { loadingContainer, loaderContainer } = styles;

const { ExtraPadding } = industrialParkDetailStyles;

const IndustrialPark = ({ ...props }) => {
  const { pageContext } = props;
  const data = pageContext.pageData ? pageContext.pageData : null;

  if (!data) {
    return;
  }

  const programLink = pageContext.programUrl;
  const headerLinks = getHeaderLInks('', programLink, data.noLinks);

  const { headerLogo, headerLogoScroll, screenName } = (data || {}).node || {};

  const logos = pageContext.logos || [];
  const [industrialParkData, setIndustrialParkData] = useState(null);
  const [coordinates, setCoordinates] = useState([22.7578, 5.9806]);
  const [mapLogisticsMarkers, setMapLogisticsMarkers] = useState([]);
  const [
    filteredMapLogisticsFilters,
    setFilteredMapLogisticsFilters,
  ] = useState([]);
  // const [industrialParkGallery, setIndustrialParkGallery] = useState(null);
  const { t, ready } = useTranslation();

  const [loading, setLoading] = useState(false);

  const [newExpanded, setNewExpanded] = useState([]);
  // const [galleryLoading, setGalleryLoading] = useState(false);

  const [mapFilterstate, setMapFilterState] = React.useState({
    Airport: false,
    Port: false,
    Capital: false,
  });

  const [mapFilterAccordion, setMapFilterAccordion] = useState(true);

  const handleChange = event => {
    setMapFilterState({
      ...mapFilterstate,
      [event.target.name]: event.target.checked,
    });
  };

  const getDistanceInKms = distanceInMeters => {
    if (distanceInMeters) {
    return ((distanceInMeters) / 1000).toFixed(1);
    } else {
      return 0;
    }
  }

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      window.location.search.includes('id')
    ) {
      setLoading(true);
      const industrialParkID = parseInt(
        window.location.search.replace('?id=', '')
      );
      apiSauceInstance
        .get(`${Constants.getIndustrialParkByID}/${industrialParkID}`)
        .then(({ data, status }) => {
          const refinedData = processData(data);
          if (status !== 400) {
            setIndustrialParkData({
              ...refinedData,
            });
            if (
              (refinedData || {}).coordinates[1] &&
              (refinedData || {}).coordinates[0]
            ) {
              setCoordinates([
                refinedData.coordinates[0],
                refinedData.coordinates[1],
              ]);
            }
          }
          setLoading(false);
        })
        .catch(error => {
          if (error) {
            //do something
          }
          setLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    const industrialParkID = parseInt(
      window.location.search.replace('?id=', '')
    );
    setLoading(true);
    apiSauceInstance
      .get(`${Constants.getIndustrialParkLogisticsDetails}/${industrialParkID}`)
      // .get(`${Constants.getIndustrialParkLogisticsDetails}/90`)
      .then(({ data, status }) => {
        if (status !== 400) {
          if (data && data?.length > 0) {
            data.forEach(x =>(x.distanceInMeters = getDistanceInKms(x.distanceInMeters)));
            setMapLogisticsMarkers([...data]);
          }
          
        }

        setLoading(false);
      })
      .catch(error => {
        if (error) {
          //do something
        }
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    let filteredMapFiltersArray = mapLogisticsMarkers.filter(
      obj => mapFilterstate[obj.destinationTypeName] == true
    );
    setFilteredMapLogisticsFilters(filteredMapFiltersArray);
  }, [mapLogisticsMarkers, mapFilterstate]);

  useEffect(() => {
    setLoading(true);
    let sectionList = [];

    sectionList = industrialParkData?.ipAdditionalDetails.map(obj => {
      if (obj.sectionTitle === 'SEZContact') {
        return {
          section: obj.sectionTitle,
          isOpen: true,
        };
      }
      return {
        section: obj.sectionTitle,
        isOpen: false,
      };
    });
    if (sectionList !== undefined) {
      setNewExpanded(sectionList);
    }

    if (
      industrialParkData &&
      !industrialParkData.coordinates[1] &&
      !industrialParkData.coordinates[0]
    ) {
      const searchText = `${industrialParkData.country} ${
        industrialParkData.region ? industrialParkData.region : ''
      } ${industrialParkData.city ? industrialParkData.city : ''}`;

      const url = `/${searchText}.json?access_token=${Constants.mapBoxApiKey}`;
      apiSauceInstanceMapBox
        .get(url)
        .then(mapCoordinates => {
          if (mapCoordinates.data && mapCoordinates.data.features.length > 0) {
            if (
              mapCoordinates.data.features[0].geometry &&
              mapCoordinates.data.features[0].geometry.coordinates
            ) {
              industrialParkData.coordinates[1] =
                mapCoordinates.data.features[0].geometry.coordinates[0] || 0;
              industrialParkData.coordinates[0] =
                mapCoordinates.data.features[0].geometry.coordinates[1] || 0;
              setCoordinates([
                industrialParkData.coordinates[0],
                industrialParkData.coordinates[1],
              ]);
            }
          }
          setLoading(false);
        })
        .catch(error => {
          if (error) {
            //do something
          }
          setLoading(false);
        });
    }
  }, [industrialParkData]);

  const getCoverImage = () => {
    let image = '';
    if (
      industrialParkData &&
      industrialParkData.coverImage
      // industrialParkData.industrialParkImages.length > 0
    ) {
      // const coverImage = industrialParkData.industrialParkImages.find(
      //   a => a.isCoverImage == true
      // );
      // if (coverImage) {
      //   image = coverImage.industrialParkImageSource;
      // }
      image = industrialParkData.coverImage;
    }

    if (industrialParkData !== null && !image) {
      let countryName = '';
      countryName = industrialParkData.country.split(' ').join('_');
      if (industrialParkData != null) {
        const country_id = getCountryDataID(industrialParkData.country);
        image = `${baseURL}/${imageFolderPath}${country_id}/${countryName}.jpg`;
        // image = `${baseURL}/${imageFolderPath}191/${countryName}.jpg`;
      }
    }
    return image;
  };

  const getAppendedCommaValue = value => {
    let output = '';
    if (value) {
      output = value + ', ';
    }
    return output;
  };

  let countries_data =
    t('countries_data', {
      returnObjects: true,
    }) || [];

  // const getCountryDataName = countryID => {
  //   let id = countryID;
  //   if (!ready) {
  //     return 'Loading...';
  //   } else {
  //     let data = countries_data.find(({ countryID }) => countryID == id);
  //     return data ? data?.countryName : '';
  //   }
  // };

  const getCountryDataID = countryName => {
    let name = countryName;
    if (!ready) {
      return 'Loading...';
    } else {
      let data = countries_data.find(({ countryName }) => countryName == name);
      return data ? data?.countryID : '';
    }
  };

  function updateSectionStatus(sectionName, isOpen) {
    const updatedArr = newExpanded.map(obj => {
      if (obj.section === sectionName) {
        return { ...obj, isOpen: isOpen };
      }
      return obj;
    });
    setNewExpanded(updatedArr);
  }

  function setOpenAll() {
    const updatedArr = newExpanded.map(obj => {
      return { ...obj, isOpen: true };
    });
    setNewExpanded(updatedArr);
  }

  function setCloseAll() {
    const updatedArr = newExpanded.map(obj => {
      return { ...obj, isOpen: false };
    });
    setNewExpanded(updatedArr);
  }

  // Example usage:
  // const updatedSectionArr = updateSectionStatus('SEZCharacteristics', true);

  const handleExpandChange = panel => (event, newExpanded) => {
    updateSectionStatus(panel, newExpanded);
  };

  const skypeLogo = css`
    color: #00aec8;
    font-size: 30px;
  `;

  const getSocialMediaIconWithLink = attribute => {
    switch (attribute.key) {
      case 'Instagram':
        return (
          <a
            href={`https://www.instagram.com/${attribute.value}`}
            target="_blank"
            rel="noreferrer"
          >
            <IconButton color="primary" component="span">
              <Instagram
                style={{
                  color: '#00aec8',
                }}
              />
            </IconButton>
          </a>
        );

      case 'Facebook':
        return (
          <a
            href={`https://m.facebook.com/${attribute.value}`}
            target="_blank"
            rel="noreferrer"
          >
            <IconButton color="primary" component="span">
              <FacebookIcon
                style={{
                  color: '#00aec8',
                }}
              />
            </IconButton>
          </a>
        );

      case 'Twitter':
        return (
          <a
            href={`https://twitter.com/${attribute.value}`}
            target="_blank"
            rel="noreferrer"
          >
            <IconButton color="primary" component="span">
              <TwitterIcon
                style={{
                  color: '#00aec8',
                }}
              />
            </IconButton>
          </a>
        );

      case 'Youtube':
        return (
          <a
            href={`https://www.youtube.com/${attribute.value}`}
            target="_blank"
            rel="noreferrer"
          >
            <IconButton color="primary" component="span">
              <YoutubeIcon
                style={{
                  color: '#00aec8',
                }}
              />
            </IconButton>
          </a>
        );

      case 'Skype':
        return (
          <a
            href={`https://join.skype.com/invite/${attribute.value}`}
            target="_blank"
            rel="noreferrer"
          >
            <IconButton color="primary" component="span">
              <AiFillSkype css={skypeLogo} />
            </IconButton>
          </a>
        );

      case 'LinkedIn':
        return (
          <a
            href={`https://www.linkedin.com/${attribute.value}`}
            target="_blank"
            rel="noreferrer"
          >
            <IconButton color="primary" component="span">
              <LinkedInIcon
                style={{
                  color: '#00aec8',
                }}
              />
            </IconButton>
          </a>
        );

      default:
        return (
          <a href={attribute.value}>
            <IconButton color="primary" component="span">
              <TwitterIcon />
            </IconButton>
          </a>
        );
      // You can add more cases for other attributes as needed
    }
  };

  const getClickableLink = link => {
    return link.startsWith('http://') || link.startsWith('https://')
      ? link
      : `http://${link}`;
  };

  const valueOutput = ele => {
    if (ele.key == 'IsISOCertified') {
      return <p>{ele.value == true ? 'Yes' : 'No'}</p>;
    }

    if (isNumber(ele.value)) {
      return <p>{ele.value}</p>;
    }

    if (isEmpty(ele.value)) {
      return <p>{t('noDetail')}</p>;
    } else {
      if (Array.isArray(ele.value)) {
        return (
          <GridContainer>
            {ele.value.map((val, index) => {
              return (
                <GridItem
                  key={index}
                  xs={12}
                  sm={12}
                  className={'ip-attribute-chips'}
                >
                  {val}
                </GridItem>
              );
            })}
          </GridContainer>
        );
      } else {
        return <p>{ele.value}</p>;
      }
    }
  };

  const BlueSwitch = withStyles({
    switchBase: {
      color: '#bbb',
      '&$checked': {
        color: '#00aec8',
      },
      '&$checked + $track': {
        backgroundColor: '#00aec8',
      },
    },
    checked: {},
    track: {},
  })(Switch);

  const mapMarkerInfo = (type, entityId) => {
    switch (type) {
      case 'sez': {
        const markerLogisticsDetails = [];

        const nearestCapital = mapLogisticsMarkers.find(
          ({ destinationTypeName, distanceOrder }) =>
            destinationTypeName == 'Capital' && distanceOrder == 1
        );

        if (nearestCapital) markerLogisticsDetails.push(nearestCapital);

        const nearestAirport = mapLogisticsMarkers.find(
          ({ destinationTypeName, distanceOrder }) =>
            destinationTypeName == 'Airport' && distanceOrder == 1
        );

        if (nearestAirport) markerLogisticsDetails.push(nearestAirport);

        const nearestPort = mapLogisticsMarkers.find(
          ({ destinationTypeName, distanceOrder }) =>
            destinationTypeName == 'Port' && distanceOrder == 1
        );

        if (nearestPort) markerLogisticsDetails.push(nearestPort);

        return (
          <div>
            <div style={{ fontSize: '18px', marginBottom: '10px' }}>
              <b>{industrialParkData?.name}</b>
            </div>
            {markerLogisticsDetails.map((ele, index) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: '10px',
                  }}
                  key={index}
                >
                  <span style={{ color: '#aaa' }}>
                    {t(`Nearest${ele.destinationTypeName}`)}
                  </span>
                  <span style={{ fontSize: '16px' }}>
                    {ele.destinationName}&nbsp;
                    {ele?.uniqueCode && `(${ele?.uniqueCode})`}
                  </span>
                  <span style={{ fontSize: '14px' }}>
                    { ele.distanceInMeters } km
                  </span>
                </div>
              );
            })}
          </div>
        );
      }
      case 'Airport':
      case 'Port':
      case 'Capital': {
        const markerInfo = mapLogisticsMarkers.find(
          ({ destinationTypeName, destinationName }) =>
            destinationTypeName == type && destinationName == entityId
        );

        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: '10px',
            }}
          >
            <span style={{ color: '#aaa' }}>
              {t(`${markerInfo.destinationTypeName.toLowerCase()}`)}
            </span>
            <span style={{ fontSize: '16px', marginBottom: '5px' }}>
              {markerInfo.destinationName} ({markerInfo.uniqueCode})
            </span>
            <span style={{ color: '#aaa' }}>
              {t('distanceFrom')} {industrialParkData?.name}
            </span>
            <span style={{ fontSize: '14px' }}>
              { markerInfo.distanceInMeters} km
            </span>
          </div>
        );
      }

      default:
        return <div>Info...</div>;
      // You can add more cases for other attributes as needed
    }
  };

  return (
    <Layout
      programLink={programLink}
      imageName={getCoverImage()}
      customView={getCoverImage()}
      headerLinks={headerLinks}
      logos={logos}
      headerLogo={headerLogo}
      headerLogoScroll={headerLogoScroll}
      visitedLinks={[
        { url: '/', key: 'home', name: 'Home' },
        {
          url: '/industrialParks',
          key: 'industrial_parks',
          name: 'Industrial Parks',
        },
      ]}
      currentPage={industrialParkData ? industrialParkData.name : ''}
      screenName={screenName}
    >
      {!loading ? (
        <>
          <div className="details-map-filter">
            <Accordion
              className="expanded-accordion"
              expanded={mapFilterAccordion}
              onChange={() => setMapFilterAccordion(prevState => !prevState)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className="expand-icon" />}
              >
                <Typography className="accordion-title">
                  {t('mapFilters')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <BlueSwitch
                        checked={mapFilterstate.Airport}
                        onChange={handleChange}
                        name="Airport"
                      />
                    }
                    label={t('airport')}
                  />
                  <FormControlLabel
                    control={
                      <BlueSwitch
                        checked={mapFilterstate.Port}
                        onChange={handleChange}
                        name="Port"
                      />
                    }
                    label={t('port')}
                  />
                  <FormControlLabel
                    control={
                      <BlueSwitch
                        checked={mapFilterstate.Capital}
                        onChange={handleChange}
                        name="Capital"
                      />
                    }
                    label={t('capital')}
                  />
                </FormGroup>
              </AccordionDetails>
            </Accordion>
          </div>
          <MapWrapper
            projects={[
              {
                coordinates: coordinates,
                id: 0,
                regionName: industrialParkData
                  ? getAppendedCommaValue(
                      industrialParkData.region ? industrialParkData.region : ''
                    ) +
                      getAppendedCommaValue(
                        industrialParkData.city ? industrialParkData.city : ''
                      ) +
                      // getCountryDataName(industrialParkData.country.countryID) ||
                      industrialParkData.country || ''
                  : '',
                currentPage: 'sez',
              },
            ]}
            mapLogisticsFilters={filteredMapLogisticsFilters}
            mapType={'sez'}
            mapMarkerInfo={mapMarkerInfo}
          />
        </>
      ) : (
        ''
      )}

      <div className="container-max">
        {data && industrialParkData ? (
          <div style={{ marginTop: '70px', marginBottom: '40px' }}>
            <GridContainer css={ExtraPadding}>
              <GridItem xs={12} sm={12} md={2}>
                <div>
                  <span className="logoImage">
                    <img
                      src={
                        industrialParkData &&
                        industrialParkData.logo &&
                        industrialParkData.logo.length > 0
                          ? industrialParkData.logo
                          : ''
                      }
                      alt=""
                    ></img>
                  </span>
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <div className="project-title">
                  <h3>{industrialParkData.name}</h3>
                </div>
                <div className="investment-proposal">
                  <p>{industrialParkData.description}</p>
                </div>
              </GridItem>

              <GridItem xs={12} sm={12} md={3}>
                <Slide bottom>
                  <NavPills
                    color="customPrimary"
                    alignCenter={true}
                    tabs={[
                      {
                        tabIcon: Twitter,
                        tabContent: (
                          <div className="twitterHeight">
                            <TwitterTimelineEmbed
                              key={industrialParkData.twitter}
                              sourceType="profile"
                              screenName={industrialParkData.twitter}
                              options={{ height: 560 }}
                            />
                          </div>
                        ),
                      },
                    ]}
                  />
                </Slide>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <div>
                  {industrialParkData ? (
                    <div className="org-details">
                      <h3>{t('industrial_park_details')}</h3>

                      <div className="org-summary">
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginBottom: '15px',
                          }}
                        >
                          <IconButton
                            color="primary"
                            component="span"
                            onClick={setOpenAll}
                            style={{
                              backgroundColor: '#00aec8',
                              marginRight: '10px',
                            }}
                            title={t('expandAll')}
                          >
                            <UnfoldMoreIcon
                              style={{
                                color: 'white',
                              }}
                            />
                          </IconButton>

                          <IconButton
                            color="primary"
                            component="span"
                            onClick={setCloseAll}
                            style={{
                              backgroundColor: '#00aec8',
                            }}
                            title={t('collapseAll')}
                          >
                            <UnfoldLessIcon
                              style={{
                                color: 'white',
                              }}
                            />
                          </IconButton>
                        </div>
                        {industrialParkData.ipAdditionalDetails.map(
                          (section, index) => {
                            if (
                              section.sectionTitle == 'SEZContact' ||
                              section.sectionTitle == 'SEZManagerContact' ||
                              section.sectionTitle == 'SEZCharacteristics' ||
                              section.sectionTitle == 'SEZINVJOBCreation' ||
                              section.sectionTitle == 'SEZGovernance' ||
                              section.sectionTitle == 'SEZInfrastructure' ||
                              section.sectionTitle == 'SEZCharacteristics'
                            ) {
                              return (
                                <>
                                  <Accordion
                                    className="expanded-accordion"
                                    expanded={newExpanded?.some(
                                      obj =>
                                        obj.section === section.sectionTitle &&
                                        obj.isOpen === true
                                    )}
                                    onChange={handleExpandChange(
                                      section.sectionTitle
                                    )}
                                    key={index}
                                  >
                                    <AccordionSummary
                                      expandIcon={
                                        <ExpandMoreIcon className="expand-icon" />
                                      }
                                      aria-controls={section.sectionTitle}
                                      id={section.sectionTitle}
                                    >
                                      <Typography className="accordion-title">
                                        {t(section.sectionTitle)}
                                      </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <Grid container spacing={4}>
                                        {section.attributes ? (
                                          isEmpty(section.attributes) ? (
                                            <Grid
                                              md={12}
                                              className="grid-details"
                                              alignContent="center"
                                              alignItems="center"
                                              justifyContent="center"
                                            >
                                              {t('noInfo')}
                                            </Grid>
                                          ) : (
                                            section.attributes.map((obj, i) => {
                                              return Array.isArray(obj) ? (
                                                obj.map(innerObj => (
                                                  <Grid
                                                    md={6}
                                                    className="grid-details"
                                                    key={i}
                                                  >
                                                    <div className="summary-item-logo">
                                                      <span className="info-icon-container">
                                                        <InfoOutlined />
                                                      </span>
                                                    </div>
                                                    <div className="data-overflow">
                                                      <span className="summary-item-title">
                                                        {t(innerObj.key)}
                                                      </span>

                                                      {innerObj.key ==
                                                        'Email' ||
                                                      innerObj.key ==
                                                        'Telephone' ? (
                                                        <>
                                                          {innerObj.key ==
                                                            'Email' && (
                                                            <GridContainer>
                                                              <GridItem
                                                                key={index}
                                                                xs={12}
                                                                sm={12}
                                                                className={
                                                                  'ip-attribute-chips'
                                                                }
                                                              >
                                                                <div
                                                                  style={{
                                                                    display:
                                                                      'flex',
                                                                  }}
                                                                >
                                                                  <EmailIcon
                                                                    style={{
                                                                      marginRight:
                                                                        '5px',
                                                                    }}
                                                                  />
                                                                  <a
                                                                    href={`mailto:${innerObj.value}`}
                                                                    style={{
                                                                      color:
                                                                        '#fff',
                                                                    }}
                                                                  >
                                                                    <p
                                                                      style={{
                                                                        marginBottom:
                                                                          '5px',
                                                                        wordBreak:
                                                                          'break-all',
                                                                      }}
                                                                    >
                                                                      {
                                                                        innerObj.value
                                                                      }
                                                                    </p>
                                                                  </a>
                                                                </div>
                                                              </GridItem>
                                                            </GridContainer>
                                                          )}

                                                          {innerObj.key ==
                                                            'Telephone' && (
                                                            <GridContainer>
                                                              <GridItem
                                                                key={index}
                                                                xs={12}
                                                                sm={12}
                                                                className={
                                                                  'ip-attribute-chips'
                                                                }
                                                              >
                                                                <div
                                                                  style={{
                                                                    display:
                                                                      'flex',
                                                                  }}
                                                                >
                                                                  <PhoneIcon
                                                                    style={{
                                                                      marginRight:
                                                                        '5px',
                                                                    }}
                                                                  />
                                                                  <a
                                                                    href={`tel:${innerObj.value}`}
                                                                    style={{
                                                                      color:
                                                                        '#fff',
                                                                    }}
                                                                  >
                                                                    <p
                                                                      key={
                                                                        index
                                                                      }
                                                                      style={{
                                                                        marginBottom:
                                                                          '5px',
                                                                        wordBreak:
                                                                          'break-all',
                                                                      }}
                                                                    >
                                                                      {
                                                                        innerObj.value
                                                                      }
                                                                    </p>
                                                                  </a>
                                                                </div>
                                                              </GridItem>
                                                            </GridContainer>
                                                          )}
                                                        </>
                                                      ) : (
                                                        <p>{innerObj.value}</p>
                                                      )}
                                                    </div>
                                                  </Grid>
                                                ))
                                              ) : (
                                                <Grid
                                                  md={6}
                                                  className="grid-details"
                                                  key={i}
                                                >
                                                  <div className="summary-item-logo">
                                                    <span className="info-icon-container">
                                                      <InfoOutlined />
                                                    </span>
                                                  </div>
                                                  <div className="data-overflow">
                                                    <span className="summary-item-title">
                                                      {t(obj.key)}
                                                    </span>

                                                    {(obj.key == 'Email' ||
                                                      obj.key == 'Telephone' ||
                                                      obj.key == 'Social' ||
                                                      obj.key == 'Website' ||
                                                      obj.key ==
                                                        'RegulatoryAuthorityWebsite' ||
                                                      obj.key ==
                                                        'IsISOCertified' ||
                                                      obj.key ==
                                                        'IndustrialParkSectorMapping' ||
                                                      obj.key ==
                                                        'DedicatedParkZoneLegalFramework' ||
                                                      obj.key ==
                                                        'KeyTaxandFinancialIncentives') &&
                                                    (!isEmpty(obj.value) ||
                                                      !isEmpty(
                                                        obj?.value?.description
                                                      )) ? (
                                                      <>
                                                        {obj.key == 'Email' && (
                                                          <GridContainer>
                                                            {obj.value.map(
                                                              (ele, index) => {
                                                                return (
                                                                  <GridItem
                                                                    key={index}
                                                                    xs={12}
                                                                    sm={12}
                                                                    className={
                                                                      'ip-attribute-chips'
                                                                    }
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        display:
                                                                          'flex',
                                                                      }}
                                                                    >
                                                                      <EmailIcon
                                                                        style={{
                                                                          marginRight:
                                                                            '5px',
                                                                        }}
                                                                      />
                                                                      <a
                                                                        href={`mailto:${ele}`}
                                                                        style={{
                                                                          color:
                                                                            '#fff',
                                                                        }}
                                                                      >
                                                                        <p
                                                                          style={{
                                                                            marginBottom:
                                                                              '5px',
                                                                            wordBreak:
                                                                              'break-all',
                                                                          }}
                                                                        >
                                                                          {ele}
                                                                        </p>
                                                                      </a>
                                                                    </div>
                                                                  </GridItem>
                                                                );
                                                              }
                                                            )}
                                                          </GridContainer>
                                                        )}

                                                        {obj.key ==
                                                          'Telephone' && (
                                                          <GridContainer>
                                                            {obj.value.map(
                                                              (ele, index) => {
                                                                return (
                                                                  <GridItem
                                                                    key={index}
                                                                    xs={12}
                                                                    sm={12}
                                                                    className={
                                                                      'ip-attribute-chips'
                                                                    }
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        display:
                                                                          'flex',
                                                                      }}
                                                                    >
                                                                      <PhoneIcon
                                                                        style={{
                                                                          marginRight:
                                                                            '5px',
                                                                        }}
                                                                      />
                                                                      <a
                                                                        href={`tel:${ele}`}
                                                                        style={{
                                                                          color:
                                                                            '#fff',
                                                                        }}
                                                                      >
                                                                        <p
                                                                          key={
                                                                            index
                                                                          }
                                                                          style={{
                                                                            marginBottom:
                                                                              '5px',
                                                                            wordBreak:
                                                                              'break-all',
                                                                          }}
                                                                        >
                                                                          {ele}
                                                                        </p>
                                                                      </a>
                                                                    </div>
                                                                  </GridItem>
                                                                );
                                                              }
                                                            )}
                                                          </GridContainer>
                                                        )}

                                                        {obj.key ==
                                                          'Social' && (
                                                          <div>
                                                            {obj.value.map(
                                                              socialLink => {
                                                                return getSocialMediaIconWithLink(
                                                                  socialLink
                                                                );
                                                              }
                                                            )}
                                                          </div>
                                                        )}

                                                        {(obj.key ===
                                                          'RegulatoryAuthorityWebsite' ||
                                                          obj.key ===
                                                            'Website') && (
                                                          <GridContainer>
                                                            <GridItem
                                                              key={index}
                                                              xs={12}
                                                              sm={12}
                                                              className={
                                                                'ip-attribute-chips'
                                                              }
                                                            >
                                                              <div
                                                                style={{
                                                                  display:
                                                                    'flex',
                                                                }}
                                                              >
                                                                <LanguageIcon
                                                                  style={{
                                                                    marginRight:
                                                                      '5px',
                                                                  }}
                                                                />
                                                                <a
                                                                  rel="noreferrer"
                                                                  href={getClickableLink(
                                                                    obj.value
                                                                  )}
                                                                  target="_blank"
                                                                  style={{
                                                                    color:
                                                                      '#fff',
                                                                  }}
                                                                >
                                                                  <p
                                                                    style={{
                                                                      marginBottom:
                                                                        '5px',
                                                                      wordBreak:
                                                                        'break-all',
                                                                    }}
                                                                  >
                                                                    {obj.value}
                                                                  </p>
                                                                </a>
                                                              </div>
                                                            </GridItem>
                                                          </GridContainer>
                                                        )}

                                                        {obj.key ==
                                                          'IndustrialParkSectorMapping' && (
                                                          <GridContainer>
                                                            {obj.value.map(
                                                              (ele, index) => {
                                                                return (
                                                                  <GridItem
                                                                    key={index}
                                                                    xs={12}
                                                                    sm={12}
                                                                    className={
                                                                      'ip-attribute-chips'
                                                                    }
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        display:
                                                                          'flex',
                                                                        alignItems:
                                                                          'center',
                                                                        justifyContent:
                                                                          'space-between',
                                                                      }}
                                                                    >
                                                                      <span>
                                                                        {
                                                                          ele.value
                                                                        }
                                                                      </span>

                                                                      {ele.isPrimary && (
                                                                        <CheckCircleIcon
                                                                          style={{
                                                                            color:
                                                                              '#fff',
                                                                          }}
                                                                        />
                                                                      )}
                                                                    </div>
                                                                  </GridItem>
                                                                );
                                                              }
                                                            )}
                                                          </GridContainer>
                                                        )}

                                                        {(obj.key ==
                                                          'DedicatedParkZoneLegalFramework' ||
                                                          obj.key ==
                                                            'KeyTaxandFinancialIncentives') &&
                                                          isEmpty(
                                                            obj.value
                                                              .description
                                                          ) && (
                                                            <p>
                                                              {t('noDetail')}
                                                            </p>
                                                          )}

                                                        {(obj.key ==
                                                          'DedicatedParkZoneLegalFramework' ||
                                                          obj.key ==
                                                            'KeyTaxandFinancialIncentives') &&
                                                          !isEmpty(
                                                            obj.value
                                                              .description
                                                          ) && (
                                                            <div>
                                                              <p>
                                                                {
                                                                  obj.value
                                                                    .description
                                                                }
                                                              </p>
                                                              {obj.value
                                                                .attachments
                                                                .length >=
                                                                1 && (
                                                                <p
                                                                  style={{
                                                                    color:
                                                                      '#868686',
                                                                  }}
                                                                >
                                                                  {t(
                                                                    'attachments'
                                                                  )}
                                                                  :
                                                                </p>
                                                              )}
                                                              <div
                                                                style={{
                                                                  display:
                                                                    'flex',
                                                                  flexDirection:
                                                                    'column',
                                                                }}
                                                              >
                                                                {obj.value.attachments.map(
                                                                  (
                                                                    attachment,
                                                                    index
                                                                  ) => (
                                                                    <div
                                                                      key={
                                                                        index
                                                                      }
                                                                    >
                                                                      <a
                                                                        href={getClickableLink(
                                                                          attachment.attachment
                                                                        )}
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                      >
                                                                        <Button
                                                                          variant="contained"
                                                                          style={{
                                                                            backgroundColor:
                                                                              '#00aec8',
                                                                            color:
                                                                              '#fff',
                                                                            marginBottom:
                                                                              '5px',
                                                                          }}
                                                                          endIcon={
                                                                            <OpenInBrowserIcon
                                                                              style={{
                                                                                color:
                                                                                  '#fff',
                                                                              }}
                                                                            />
                                                                          }
                                                                        >
                                                                          {t(
                                                                            'open'
                                                                          )}
                                                                        </Button>
                                                                      </a>
                                                                      <p>
                                                                        {
                                                                          attachment.description
                                                                        }
                                                                      </p>
                                                                    </div>
                                                                  )
                                                                )}
                                                              </div>
                                                            </div>
                                                          )}
                                                      </>
                                                    ) : (
                                                      <>
                                                        {valueOutput(obj)}
                                                        {/* <p>
                                                          {obj.key ===
                                                          'IsISOCertified'
                                                            ? obj.value == true
                                                              ? 'Yes'
                                                              : 'No'
                                                            : isEmpty(obj.value)
                                                            ? t('noDetail')
                                                            : obj.value}
                                                        </p> */}
                                                      </>
                                                    )}
                                                  </div>
                                                </Grid>
                                              );
                                            })
                                          )
                                        ) : null}
                                      </Grid>
                                    </AccordionDetails>
                                  </Accordion>
                                </>
                              );
                            }

                            if (section.sectionTitle == 'SEZImageGallery') {
                              return (
                                <Accordion
                                  className="expanded-accordion"
                                  expanded={newExpanded?.some(
                                    obj =>
                                      obj.section === section.sectionTitle &&
                                      obj.isOpen === true
                                  )}
                                  onChange={handleExpandChange(
                                    section.sectionTitle
                                  )}
                                  key={index}
                                >
                                  <AccordionSummary
                                    expandIcon={
                                      <ExpandMoreIcon className="expand-icon" />
                                    }
                                    aria-controls={section.sectionTitle}
                                    id={section.sectionTitle}
                                  >
                                    <Typography className="accordion-title">
                                      {t(section.sectionTitle)}
                                    </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <Grid container spacing={4}>
                                      <Grid
                                        md={12}
                                        className="grid-details"
                                        alignContent="center"
                                        alignItems="center"
                                        justifyContent="center"
                                      >
                                        {isEmpty(section.attributes) ? (
                                          t('noInfo')
                                        ) : (
                                          <ImageCarousel
                                            items={section.attributes}
                                          />
                                        )}
                                      </Grid>
                                    </Grid>
                                  </AccordionDetails>
                                </Accordion>
                              );
                            }
                          }
                        )}
                      </div>
                    </div>
                  ) : null}
                </div>
              </GridItem>
            </GridContainer>
          </div>
        ) : (
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} css={loadingContainer}>
              <div>
                {loading ? (
                  <Container width="100%" css={loaderContainer}>
                    <div className="three-dots-loader">
                      <ThreeDots
                        color="#5990ff"
                        height={100}
                        width={100}
                        timeout={10000}
                      />
                    </div>
                  </Container>
                ) : (
                  t('No Industrial Park data to display')
                )}
              </div>
            </GridItem>
          </GridContainer>
        )}
      </div>
    </Layout>
  );
};

export default IndustrialPark;

IndustrialPark.propTypes = {
  pageContext: PropTypes.object,
  location: PropTypes.object,
};
