import React, { useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import Paper from '@material-ui/core/Paper';
import './carouselStyle.scss';
// import Settings, { DefaultSettingsT } from './Settings';

const ImageCarousel = ({ items }) => {
  // const [settings, setSettings] = useState(DefaultSettingsT);

  return (
    <div style={{ color: '#494949', width: '100vw' }}>
      <Carousel className="SecondExample" autoPlay={false}>
        {items.map((item, index) => {
          return <Project item={item} key={index} />;
        })}
      </Carousel>
      {/* <Settings settings={settings} setSettings={setSettings} /> */}
    </div>
  );
};

function Project({ item }) {
  return (
    <Paper className="Project" elevation={10}>
      <img src={item} alt="Gallery" />
    </Paper>
  );
}

export default ImageCarousel;
