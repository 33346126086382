export default function processData(inputData) {
  let result = {
    name: '',
    description: '',
    logo: '',
    coverImage: '',
    twitter: '',
    coordinates: ['', ''],
    country: '',
    city: '',
    region: '',
    ipAdditionalDetails: [],
  };
  let social = {
    key: 'Social',
    value: [],
  };

  // Extracting and organizing data from the input array
  inputData.forEach(section => {
    switch (section.section) {
      case 'SEZGeneral':
        section.attributes.forEach(attribute => {
          switch (attribute.key) {
            case 'Title':
              result.name = attribute.value;
              break;
            case 'Description':
              result.description = attribute.value;
              break;
            case 'Logo':
              result.logo = attribute.value;
              break;
            case 'Latitude':
              result.coordinates[0] = attribute.value;
              break;
            case 'Longitude':
              result.coordinates[1] = attribute.value;
              break;
            case 'Country':
              result.country = attribute.value;
              break;
            case 'Region':
              result.region = attribute.value;
              break;
            case 'City':
              result.city = attribute.value;
              break;

            // You can add more cases for other attributes as needed
          }
        });
        break;

      case 'SEZManagerContact':
        // Push SEZManagerContact object into ipAdditionalDetails array
        result.ipAdditionalDetails.push({
          sectionTitle: section.section,
          attributes: section.attributes,
        });
        break;

      case 'SEZImageGallery': {
        section.attributes.value.forEach(image => {
          if (image.isPrimary) {
            result.coverImage = image.value;
          }
        });
        // Modify SEZImageGallery structure and push it into ipAdditionalDetails array
        let imageGalleryDetails = {
          sectionTitle: section.section,
          attributes: [],
        };

        section.attributes.value.forEach(image => {
          imageGalleryDetails.attributes.push(image.value);
        });

        result.ipAdditionalDetails.push(imageGalleryDetails);
        break;
      }

      case 'SEZContact': {
        let contactDetails = {
          sectionTitle: section.section,
          attributes: [],
        };
        // Push SEZContact object into ipAdditionalDetails array
        section.attributes.forEach(attribute => {
          switch (attribute.key) {
            case 'Instagram':
              social.value.push(attribute);
              break;
            case 'Facebook':
              social.value.push(attribute);
              break;
            case 'Twitter':
              social.value.push(attribute);
              break;
            case 'Youtube':
              social.value.push(attribute);
              break;
            case 'Skype':
              social.value.push(attribute);
              break;
            case 'LinkedIn':
              social.value.push(attribute);
              break;
            default:
              contactDetails.attributes.push(attribute);
            // You can add more cases for other attributes as needed
          }
        });

        contactDetails.attributes.push(social);

        // Push additional attributes from SEZGeneral section
        let generalAttributes = inputData.find(
          sec => sec.section === 'SEZGeneral'
        ).attributes;
        generalAttributes.forEach(attribute => {
          if (attribute.key === 'Address' || attribute.key === 'Website') {
            contactDetails.attributes.unshift(attribute);
          }
        });

        result.ipAdditionalDetails.push(contactDetails);
        let twitterValue = section.attributes.find(
          attr => attr.key === 'Twitter'
        );

        if (
          twitterValue &&
          twitterValue.value &&
          twitterValue.value.trim() !== ''
        ) {
          result.twitter = twitterValue.value;
        } else {
          result.twitter = 'InvestinACP';
        }
        break;
      }

      case 'SEZINVJOBCreation':
        // Push SEZINVJOBCreation object into ipAdditionalDetails array
        result.ipAdditionalDetails.push({
          sectionTitle: section.section,
          attributes: section.attributes,
        });
        break;
      case 'SEZGovernance':
        // Push SEZGovernance object into ipAdditionalDetails array
        result.ipAdditionalDetails.push({
          sectionTitle: section.section,
          attributes: section.attributes,
        });
        break;
      case 'SEZInfrastructure': {
        // Push SEZInfrastructure object into ipAdditionalDetails array
        result.ipAdditionalDetails.push({
          sectionTitle: section.section,
          attributes: section.attributes,
        });
        break;
      }
      case 'SEZCharacteristics': {
        // Push SEZInfrastructure object into ipAdditionalDetails array
        result.ipAdditionalDetails.push({
          sectionTitle: section.section,
          attributes: section.attributes,
        });
        break;
      }

      // Add cases for other sections if required
    }
  });

  return result;
}
